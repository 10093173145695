.TechnicalHolderPage{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.TechnicalHolderPage-Left{
    width: 30%;
    border-radius: 15px;
    background-color: darkgray;
    margin: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
    flex-direction: column;

}
.TechnicalHolderPage-Left button{
    width: 75%;
    height: 35px;
    border-radius: 25px;
    margin: 2%;
}

.TechnicalHolderPage-Left button:hover{
    background-color: lightblue;
}

.TechnicalHolderPage-Right{
    height: 450px;
    width: 65%;
    border-radius: 15px;
    margin: 2%;
    background-color: darkgray;
    display: flex;
    justify-content: center;
    align-items: center;
}



/* AddProductForm.css */

.add-product-container {
    width: 85%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .add-product-form label,
  .add-product-form textarea,
  .add-product-form input {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .add-product-form input,
  .add-product-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-product-form button {
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-product-form button:hover {
    background-color: #0056b3;
  }
  



  .productContainer {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    width: 300px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .productContainer h3 {
    font-size: 1.5em;
    margin-bottom: 8px;
  }
  
  .productContainer h4 {
    font-size: 1.2em;
    margin-bottom: 8px;
  }
  
  .productContainer p {
    font-size: 1em;
    margin-bottom: 16px;
  }
  
  .productContainer button {
    background-color: #ff6347;
    border: none;
    color: #fff;
    font-size: 1em;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .productContainer button:hover {
    background-color: #ff4500;
  }
  
  .productsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  