.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: lightblue; /* Light blue background color */
    padding: 10px 20px;
    color: black; /* Black text color */
    width: 100%;
  }
  .centerHeader{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Header-Left {
    display: flex;
    align-items: center;
  }
  
  .Header-Left img {
    width: 40px; /* Adjust the image width as needed */
    height: 40px; /* Adjust the image height as needed */
    border-radius: 50%; /* Make the image round */
    margin-right: 10px;
  }
  
  .Header-Left h1 {
    font-size: 24px;
    margin-right: 20px;
  }
  
  .Header-Left a {
    text-decoration: none;
    color: black; /* Black link text color */
    margin-right: 20px;
    font-weight: bold;
  }

  .Header-Right{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .Header-Right button {
    background-color: black; /* Black button background color */
    color: white; /* White button text color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  /* Style for button hover effect */
  .Header-Right button:hover {
    background-color: darkgray; /* Change color on hover */
  }

  .Header-Left button {
    background-color: black; /* Black button background color */
    color: white; /* White button text color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  /* Style for button hover effect */
  .Header-Left button:hover {
    background-color: darkgray; /* Change color on hover */
  }
  
  /* You can adjust the styles according to your preferences and design requirements */
  