.Partners{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

   
    background-image: url('./Background.jpg');
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center center; 
}

.BackgroundOverlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
}
.Partners p{
    color: white;
    font-size: 24px;
}

@media screen and (max-width: 768px) {
    /* For tablet screens and smaller */
    .Partners p {
        font-size: 20px; /* Adjust the font size as needed */
    }
}

@media screen and (max-width: 480px) {
    /* For mobile screens */
    .Partners p {
        font-size: 16px; /* Adjust the font size as needed */
    }
}
.Partner-Items{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

}
.Partner-Item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18%;
    margin: 1%;
    flex-direction: column;
    text-align: center;
    /*background: linear-gradient(to bottom right, blue, pink);*/
    border-radius: 25px;


}

.Partner-Item:hover{
    cursor: pointer;
}

.Partner-Item img{
    width: 50%;
    border-radius: 10px;
    margin-top: 10px;
}


.item1{
    background-image: url('./Compass.png');
    background-size: cover; /* This will make the image cover the entire element */
    background-repeat: no-repeat;
    background-position: center center; /* Center the image */
}


.item2{
    background-image: url('./Lexus.png');
    background-size: cover; /* This will make the image cover the entire element */
    background-repeat: no-repeat;
    background-position: center center; /* Center the image */
}


