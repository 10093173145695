.Search{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.Search-Left{
    width: 30%;
    border-radius: 15px;
    background-color: darkgray;
    margin: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    flex-direction: column;

}

.Search-Left button{
  width: 75%;
  height: 35px;
  border-radius: 25px;
  margin: 2%;
}

.Search-Left button:hover{
  background-color: lightblue;
}

.Search-Right{
    height: 350px;
    width: 65%;
    border-radius: 15px;
    margin: 2%;
    background-color: darkgray;
    display: flex;
    justify-content: center;
    align-items: center;
}


.SearchArea{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* Add this to your existing CSS file or create a new CSS file and import it */

.searchAreaRadioButtons {
    display: flex;
    margin-bottom: 10px;
  }
  
    .radio-label {
    margin-right: 20px;
  }
  
  .radio-label input {
    margin-right: 5px;
  }

  
  .radio-label input[type="radio"] + span {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
  }
  
  .radio-label input[type="radio"] + span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #007BFF;
    border-radius: 50%;
  }
  
  .radio-label input[type="radio"]:checked + span:before {
    background-color: #007BFF;
  }
  
  /* RadioButton.css */
/*
.searchAreaRadioButtons {
    display: flex;
    margin-bottom: 10px;
  }
  
  .radio-label {
    margin-right: 20px;
  }
  
  .radio-label input[type="radio"] {
    margin-right: 5px;
  }
  */
  

  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
  }
  
  .modalContent {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  textarea {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    resize: none;
  }
  
  .searchButton {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 25px;
  }
  
  .searchButton:hover {
    background-color: #0056b3;
  }

  button {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }

  .SearchResults{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 95%;
  }
  
  .searchProductContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 200px;

    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }