.navbar {
    height: 100vh;
    width: 250px;
    background-color: #333;
    position: relative;
    left: 0;
    top: 0;
    overflow-y: auto;
    background-color: transparent; /* Light blue background color */

}
 

.navbarB {
    height: 100vh;
    width: 250px;
    background-color: #333;
    position: fixed; /* Set to fixed position */
    left: 0;
    top: 0;
    overflow-y: auto;
    background-color: lightblue; /* Light blue background color */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }

  .navbarB::-webkit-scrollbar {
    width: 12px;
  }
  
  /* Handle */
  .navbarB::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .navbarB::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .navbar a {
    padding: 15px;
    text-decoration: none;
    color: white;
    display: block;
  }


  
 
  

  .navbarItems{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .navbarItems img {
    width: 80px; /* Adjust the image width as needed */
    height: 80px; /* Adjust the image height as needed */
    border-radius: 25%; /* Make the image round */
  }
  
  .navbarItems h1 {
    font-size: 24px;
  }

  .navbarItems button {
    background-color: black; /* Black button background color */
    color: white; /* White button text color */
    border: none;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    width: 85%;
    margin-bottom: 5px;
  }

  .searchArea{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .searchArea button{
    width: 100%;
  }

  .userButtons{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
  }
  .userButtons button{
    width: 100%;
  }

  .MessagesAndProfile{
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;

  }
  .MessagesAndProfile button{
    width: 100%;
  }


  .headerContent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }


  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .closeIcon img {
    width: 20px; /* Adjust the width and height as needed */
    height: 20px;
  }
  