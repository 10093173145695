.FormContainer {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: lightblue;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .FormContainer h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .FormField {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .FormField label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .FormField input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .FormField button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: black;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .FormField button:hover {
    background-color: darkgray;
  }
  

  .SubmitButton {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  .FormContainer form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }