/* SearchBar.css */

.search-form {
    display: flex;
    /*margin-bottom: 20px;*/
}
  
.search-input {
    padding: 10px;
    flex-grow: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
}
  
.search-button {
    /*padding: 10px 20px;*/
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}
  
.search-button:hover {
    background-color: #0056b3;
}
  