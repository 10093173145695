
.flexBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-image: url('../Dashboard/images/img3.jpg');
    background-size: cover; /* This will make the image cover the entire element */
    background-repeat: no-repeat;
    background-position: center center; /* Center the image */
}
.ModeSelector{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    
}
.ModeSelector-Buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    margin: 2.5%;
    padding: 2.5%;
    flex-direction: column;
}

.ModeSelector-Buttons button{
    width: 85%;

    height: 125px;
    font-size: 16px;
    color: white;
    background-color: darkgray;
    margin: 10px;
    border-radius: 10px;
}

.ModeSelector-images{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
   

}

.ModeSelector-images img{
    margin: 2.5%;
    padding: 2.5%;
    max-width: 300px;
}