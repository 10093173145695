.Profile{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.Profile-Left{
    width: 30%;
    border-radius: 15px;
    background-color: darkgray;
    margin: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    flex-direction: column;

}

.Profile-Left button{
    width: 75%;
    height: 35px;
    border-radius: 25px;
    margin: 2%;
}

.Profile-Left button:hover{
    background-color: lightblue;
}

.Profile-Right{
    height: 350px;
    width: 65%;
    border-radius: 15px;
    margin: 2%;
    background-color: darkgray;
    display: flex;
    justify-content: center;
    align-items: center;
}