/* Style for ChatBoard Container */
.ChatBoard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  /* Style for Communicated People List */
  .CommunicatedPeopleList {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .CommunicatedPeopleList li {
    cursor: pointer;
    margin-bottom: 10px;
    padding: 8px;
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  
  /* Style for Selected Person's Conversation */
  .SelectedConversation {
    flex-grow: 1;
    padding: 80px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .SelectedConversation h2 {
    margin-bottom: 10px;
  }
  
  .SelectedConversation ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .SelectedConversation ul li {
    margin-bottom: 8px;
  }
  
  /* Style for Message Input */
  .MessageInput {
    width: 100%;/*calc(100% - 80px);*/
    padding: 8px;
    margin-right: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .SendButton {
    padding: 8px 16px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .SendButton:hover {
    background-color: #0056b3;
  }
  

.message{
    
}


.MessageList {

  list-style-type: none;
  padding: 0;
  margin: 0;
}

.MessageList .message {
  max-width: 70%;
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 10px;
}

.MessageList .message.left {
  background-color: #f0f0f0;
  align-self: flex-start;
}

.MessageList .message.right {
  background-color: #007bff;
  color: #ffffff;
  align-self: flex-end;
}


.right {
    text-align: right;
  }
  
  .left {
    text-align: left;
  }
  