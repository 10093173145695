.DashBoard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    /*background-image: url('https://png.pngtree.com/background/20210714/original/pngtree-calm-smooth-blue-bokeh-light-background-picture-image_1203350.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;*/
    padding: 25px;
    min-height: 500px;
}

.DashBoard-text{
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.DashBoard-image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
}
.DashBoard-image img{
    height: 600px;
}



.slick-slide img {
    height: 500px;
    width: 100%;
    object-fit: cover;
}

.carousel-container{
    text-align: center;
}



.carousel-container {
    position: relative;
    overflow-x: hidden; /* Add this line to hide horizontal overflow */
    overflow-y: hidden; /* Add this line to hide horizontal overflow */


}
  
  .image-container {
    position: relative;
    height: 500px; /* Set the height of your images */
  }
  
  .image-text {
    position: absolute;
    top: 50%; /* Position the text 50% from the top */
    left: 50%; /* Position the text 50% from the left */
    transform: translate(-50%, -50%); /* Center the text both vertically and horizontally */
    background-color: rgba(255, 255, 255, 0.5); /* Background color for the text */
    padding: 10px;
    font-size: 35px;
    font-weight: 900;
    font-family:Georgia, 'Times New Roman', Times, serif;
    border-radius: 25px;
  }
  